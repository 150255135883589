<template>
  <div class="outcome-wrapper" data-t="bet-outcome">
    <OutcomeSeparator />
    <div class="outcome" :class="props.type">
      <NuxtI18nLink
        v-if="outcome.event?.url"
        :to="outcome.event?.url"
        class="overlay-link"
      />
      <div class="top">
        <div class="outcome-info">
          <p class="outcome-name">{{ outcomeName }}</p>
          <p class="market-name">{{ marketName }}</p>
        </div>
        <div class="outcome-rate-info">
          <p v-if="isRefunded" class="rate">-</p>
          <p v-else class="rate">
            {{ truncRate(Number(props.outcome.rate)) }}
          </p>

          <div
            v-if="isVisibleOutcomeRate"
            class="icon-status"
            :style="{ color: selfStatusInExpress.color }"
          >
            <StIcon
              v-if="selfStatusInExpress.name"
              :name="selfStatusInExpress.name"
              :size="16"
            />
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="competitors" data-t="active-bet-competitors-5nn9">
          {{ competitorsNames.home }}{{ ' - ' }}{{ competitorsNames.away }}
        </p>

        <div class="status">
          <EventStatusBar
            v-if="outcome.event"
            :event="outcome.event"
            with-scores
            :compact="props.type === 'extended'"
          />
          <div v-else class="history-result">
            <StIcon
              v-if="props.type === 'compact'"
              :name="sportIcon"
              :size="16"
              class="sport-icon"
            />
            <span> {{ i18n.t('bets.ended') }}</span>
            <span class="active">{{ historyScore }}</span>
            <span> {{ historyMatchResult }}</span>
          </div>
        </div>

        <HistoryBetBreadcrumbs
          v-if="props.type === 'extended'"
          :breadcrumbs="[
            outcome.sbSportName,
            outcome.sbCategoryName,
            outcome.sbTournamentName,
          ]"
          is-mobile
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { truncRate } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import { useHistoryOutcome } from '../../../composables/useHistoryBet'
import type { ExtendedBetOutcome, Bet } from '../../../types'
import OutcomeSeparator from '../../ExpressForm/parts/OutcomeSeparator.vue'
import HistoryBetBreadcrumbs from '../../BetHistory/parts/HistoryBetBreadcrumbs.vue'

interface Props {
  outcome: ExtendedBetOutcome
  type?: 'compact' | 'extended'
  bet: Bet
}

const props = withDefaults(defineProps<Props>(), {
  type: 'compact',
})
const { outcome } = toRefs(props)
const i18n = useI18n()

const {
  outcomeName,
  marketName,
  competitorsNames,
  historyMatchResult,
  historyScore,
  selfStatusInExpress,
  isRefunded,
} = useHistoryOutcome(outcome)

const { bet } = toRefs(props)

const sportIcon = computed(() =>
  props.outcome.sbSportId
    ? (getSportIcon({ sportId: props.outcome.sbSportId }) as IconName)
    : 'all-sports',
)

const isVisibleOutcomeRate = computed(
  () => props.type === 'extended' && bet.value.type === 'express',
)
</script>

<style scoped>
.outcome-wrapper {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  gap: var(--spacing-200);
  align-items: flex-start;
  justify-content: space-between;

  color: var(--text-primary);
}

.outcome-name {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
}

.market-name {
  margin: 0;
  font: var(--desktop-text-xs-semibold);
}

.rate {
  margin: 0;

  /* stylelint-disable */
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
  line-height: 18px;
  color: var(--text-primary);
  text-align: center;
  text-overflow: ellipsis;
  letter-spacing: 0.28px;
  /* stylelint-enable */
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
}

.competitors {
  overflow: hidden;

  margin: 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.history-result {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.sport-icon {
  color: var(--text-primary);
}

.active {
  margin: 0 var(--spacing-075);
  color: var(--text-link);
}

.icon-status {
  display: flex;
}

.outcome-rate-info {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}

.outcome {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  padding: var(--spacing-075) var(--spacing-150) var(--spacing-100);

  &.extended {
    gap: var(--spacing-150);
    padding: var(--spacing-075) var(--spacing-150) var(--spacing-150);

    /* stylelint-disable */
    .rate {
      font-size: 16px;
      line-height: 20px;
    }
    /* stylelint-enable */

    .outcome-name {
      margin-bottom: var(--spacing-025);
      font: var(--mobile-headline-semibold);
    }

    .market-name {
      font: var(--mobile-caption-1-medium);
    }

    .competitors {
      font: var(--mobile-caption-1-medium);
      color: var(--text-primary);
    }

    .bottom {
      gap: var(--spacing-050);
    }
  }
}

.overlay-link {
  position: absolute;
  inset: 0;
}
</style>
